// require to make sure the configure is called before importing the App
import { featureOverwrite, featureToggle } from 'services/FeatureToggle'

const configDefaultValues = {
  VITE_API_URL: (window._appConf || {}).API_URL || import.meta.env.VITE_API_URL || '/api',
  VITE_API_VERSION: 'v1',
  VITE_API_V2_VERSION: 'v2',
  VITE_POLICIES_API_VERSION: 'v2',
  VITE_POLICIES_KPIS_API_VERSION: 'v1',
  VITE_INVOICES_API_VERSION: 'v1',
  VITE_TOD_API_VERSION: 'v1',
  VITE_ERROR_DEFAULT_TIMEOUT: 10000,
  VITE_SSO_EOLIS_URL: 'https://www.allianz-trade.com/en_global/connect-to-eolis.html',
  VITE_SSO_SMARTVIEW_URL: 'https://eolis-uatm.1placedessaisons.com/gmtb/MTB/sso.aspx',
  VITE_SSO_SMARTVIEW_WA_URL: 'https://eolis-uatm.1placedessaisons.com/gmtb/MTBWA/sso.aspx',
  VITE_SSO_EDOC_URL: 'https://edoc.allianz-trade.com/oauth2/authorize',
  VITE_SSO_COLLECTIONS_URL: 'https://www.collections.eulerhermes.com/iMX/loginMyEhSSO.do',
  VITE_SSO_TODE_DE_URL: 'https://tode-accp.mendixcloud.com/p/external-login-direct',
  VITE_SSO_MIDAS_URL: 'https://euler-midas-accp.mendixcloud.com/p/ExternalLogin',
  VITE_SSO_BILANS_SERVICE_URL: 'https://www-recette.bilans-service.allianz-trade.fr/online/login',
  VITE_LIBRARY_URL: '/library',
  VITE_BROKER_ACADEMY_URL: '/broker-academy',
  VITE_GENERIC_ACADEMY_URL: '/academy',
  VITE_AEM_CONTENT_PATH: '/myeh-content',
  VITE_LIBRARY_DAM_PATH: '/library-dam',
  VITE_LOCALE_HASH_ID: '',
  VITE_CUSTOMER_SERVICE_LINES: 'https://www.allianz-trade.com/en_global/customer-service-lines.html',
  VITE_DYNATRACE_URL: '',
  VITE_DYNATRACE_RUXIT_SCRIPT_URL: ''
}
const getFeaturesConfig = async () => {
  if (!featureToggle('tmp-micro-frontend')) {
    const response = await fetch('/static/js/config.json', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    })

    if (!response.ok) {
      throw new Error(`////// error url: ${response.url}, statusText: ${response.statusText}`)
    }
    return response.json()
  }
}

export const getConfigWithDefaultValues = (config = {}) =>
  Object.entries(configDefaultValues).reduce(
    (memo, [key, value]) => (!memo[key] ? { ...memo, [key]: value } : memo),
    config
  )

export const saveEnvironmentVariables = (config = {}) => {
  Object.entries(config).map(([key, value]) => {
    // TODO find another way
    // the issue I have is that we cannot use a react hook inside mobx state tree model actions
    window._features = { ...window._features, [key]: value }
    return null
  })
  // Overwrite FeatureToggle
  featureOverwrite()
}

function injectScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', resolve)
    script.addEventListener('error', e => reject(e.error))
    document.head.appendChild(script)
  })
}

export const loadConfig = async () => {
  try {
    const config = await getFeaturesConfig()
    const configWithDefaultValues = getConfigWithDefaultValues(config)
    saveEnvironmentVariables(configWithDefaultValues)

    try {
      if (config.VITE_DYNATRACE_URL && config.VITE_DYNATRACE_RUXIT_SCRIPT_URL) {
        await Promise.all([
          injectScript(config.VITE_DYNATRACE_URL),
          injectScript(config.VITE_DYNATRACE_RUXIT_SCRIPT_URL)
        ])
      }
    } catch (_) {
      console.error('Failed to load or inject dynatrace scripts')
    }

    return configWithDefaultValues
  } catch (error) {
    return null
  }
}
